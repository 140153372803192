<template>
	<div>
		<h2>导入记录</h2>
		<el-button @click="getRecordImportHistory" size="small" icon="el-icon-refresh-left">刷新</el-button>
		<el-table :data="tableData" v-loading="loading" :header-cell-style="{background:'#E4E7ED',color: '#606266'}" border stripe
			size="small" max-height="500px" class="margin-t-20" style="width: 100%;">
			<el-table-column label="#" type="index" width="60px"></el-table-column>
			<el-table-column label="导入时间" prop="task.created_at"></el-table-column>
			<el-table-column label="操作人" prop="task.operator.name"></el-table-column>
			<el-table-column label="名称" prop="realname"></el-table-column>
			<el-table-column label="身份证号" prop="id_no"></el-table-column>
			<el-table-column label="导入结果" prop="result"></el-table-column>
		</el-table>
		<div class="margin-t-10 flex">
			<el-pagination background layout="prev, pager, next, total, jumper" :total="total" :current-page.sync="page"
				:page-size="pageSize" @current-change="getRecordImportHistory">
			</el-pagination>
			<el-button size="small">确定</el-button>
		</div>
	</div>
</template>

<script>
	import {
		getRecordImportHistoryAPI
	} from '@/api/user-record/record.js'

	export default {
		name: 'UserRecordImportHistory',
		data() {
			return {
				tableData: [],
				page: 1,
				total: 0,
				pageSize: 0,
				loading: false,
			}
		},
		created() {
			this.getRecordImportHistory()
		},
		methods: {
			getRecordImportHistory() {
				this.loading = true

				getRecordImportHistoryAPI({
					page: this.page,
				}).then(res => {
					this.tableData = res.data
					this.total = res.total
					this.pageSize = res.per_page
					this.loading = false
				})
			}
		}
	}
</script>

<style>
</style>
